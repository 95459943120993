export default function ({ store, $axios, $cookies, req }, inject) {
    async function getCourseInfo(itemId, options) {
        return await store.dispatch('course/getCourse', { itemId, options, $axios, $cookies });
    }
    async function getRecommendedCourses(options) {
        return await store.dispatch('course/getRecommendations', { options, $axios, $cookies });
    }
    async function getRecommendedCoursesByID(courseID, options) {
        return await store.dispatch('course/getRecommendationsByCourseID', { courseID, options, $axios, $cookies });
    }

    inject('course', { getCourseInfo, getRecommendedCourses, getRecommendedCoursesByID });
}