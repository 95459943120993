
export default {
	name: 'JoinModal',
	props: {
		cc: {
			type: Boolean,
			default: false
		},
		educator: {
			type: Object,
			default: null
		},
		login: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			cardPadding: 4,
			joinErrorMessage: false,
			loginErrorMessage: false,
			isJoin: true,
			joinHeaderText: ``,
			fieldPadding: ``,
			fieldLabel: ``,
			fieldLabelContainer: `label`,
			fieldHintAlign: ``,
			educatorCheckbox: false,
			educatorCheckBoxLabel: ``,
			tfCheckbox: false,
			tfCheckboxLabel: `Receive special offers, news, and lesson content from TrueFire via email`,
			rememberCheckbox: false,
			joinForm: {
				fields: {
					email: { label: `Enter Your Email`, type: `email`, value: ``, valid: true },
					username: {
						label: `Choose a Username`, type: `text`, value: ``, minLength: 5, valid: true,
						hint: `Username must be at least 5 characters, no symbols or spaces.`,
					},
					password: {
						label: `Choose a Password`, type: `password`, value: ``, minLength: 8, valid: true,
						hint: `Your password must be 8 or more characters, must contain at least 1 Uppercase letter, 1 number, and 1 symbol.`
					}
				}
			},
			loginForm: {
				fields: {
					username: { label: `Username or Email`, type: `text`, value: ``, valid: true },
					password: { label: `Password`, type: 'password', value: '', valid: true },
				}
			}
		}
	},
	computed: {
		educatorCheckboxColor() {
			return this.educatorCheckbox ? `text-secondary` : ``;
		},
		tfCheckboxColor() {
			return this.tfCheckbox ? `text-secondary` : ``;
		},
		rememberCheckboxColor() {
			return this.rememberCheckbox ? `text-secondary` : ``;
		},

	},
	methods: {
		validate(form) {
			let valid = true;
			for (const val in form.fields) {
				if (form.fields[val].value) {
					form.fields[val].valid = true;
				} else {
					form.fields[val].valid = false;
					valid = false;
				}
			}
			return valid;
		},

		async submitJoin() {
			this.joinErrorMessage = false;
			const valid = this.validate(this.joinForm);
			let educatorId = null;
			if (this.educatorCheckbox) {
				educatorId = await this.$configs.plusNetwork();
			}
			if (valid) {
				const joinObj = {
					username: this.joinForm.fields.username.value,
					password: this.joinForm.fields.password.value,
					email: this.joinForm.fields.email.value,
				}
				if(this.tfCheckbox) joinObj.subscribe_to_truefire = this.tfCheckbox
				if(educatorId) joinObj.subscribe_to_educator = educatorId
				if(this.cc) joinObj.bypass_trial = true
				const error = await this.$member.auth.register(joinObj);
				if (error) this.joinErrorMessage = error;
				else if (this.educator) location.reload();
				// const dataObj = {
				// 	member: {
				// 		join: {
				// 			email: this.joinForm.fields.email.value,
				// 			username: this.joinForm.fields.username.value,
				// 			password: this.joinForm.fields.password.value
				// 		},
				// 		fields: ['token', 'memberusername']
				// 	}
				// };
				// if(this.ccBypass) dataObj.member.join.bypass_trial = true
				// this.$nexus(dataObj).then(data => {
				// 	// will redirect
				// }).catch(error => {
				// 	this.errorMessage = error.message || 'An error occurred'
				// })
			} else {
				this.joinErrorMessage = 'Please fill out all fields.'
			}
		},
		submitLogin() {
			console.log('window.history.back()', window.history.back());
		},

		async submitLogin2() {
			this.loginErrorMessage = false;
			const valid = this.validate(this.loginForm);
			if (valid) {
				const error = await this.$member.auth.login({
					username: this.loginForm.fields.username.value,
					password: this.loginForm.fields.password.value,
					remember_me: this.rememberCheckbox
				});
				if (error) this.loginErrorMessage = error;
				else location.reload()
			} else {
				this.loginErrorMessage = 'Please fill out all fields.';
			}
		}

	},
	created() {
		console.log("document.referrer:", document.referrer);
		console.log("window.history:", window.history)
		this.isJoin = !this.login;
			// console.log('educator for modal', this.educator)
		if (this.educator && this.educator.educator) {
			delete this.joinForm.fields.email;
			this.joinForm.fields.email = { label: `Your Email Address`, type: `email`, value: ``, valid: true };

			this.joinHeaderText = `Register on TrueFire for Free to access the platform's member features, as well as ${this.educator.educator}'s products & tools`;
			this.fieldPadding = `my-4`;
			this.fieldLabel = `text-center fw-bold pb-1`;
			this.fieldLabelContainer = `div`;
			this.fieldHintAlign = `text-center`;
			this.educatorCheckboxLabel = `Subscribe to ${this.educator.educator}'s email list`;
		}
	}
}
