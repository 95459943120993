
export default {
	data() {
		return {
			educatorData: false,
			member: {},
			educator: {
				name: 'Frank Vignola',
				id: 3424
			}
		}
	},
	async fetch() {
		const navCache = await this.$configs.getNavCache();
		const plusId = await this.$configs.plusNetwork();
		if (navCache && (navCache.id == plusId)) this.educatorData = navCache.menu;
		else {
			this.educatorData = await this.$configs.nav(`educator`).then((data) => {
				this.$configs.setNavCache(data);
				return data;
			})
		}
		console.log("educator data:",this.educatorData);
		if (this.educatorData && this.educatorData.menu && this.educatorData.menu.length) {
			for (let i = 0; i < this.educatorData.menu.length; i++) {
				this.educatorData.menu[i]['subShow'] = false;
			}
		}
		this.member = await this.$member.get(['memberemail', 'memberstatus', 'avatar', 'memberusername', 'memberstatus', 'memberbalance', 'tfu_status', 'educator_navigation']);
	}
}
