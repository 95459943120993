export default function ({ store, $axios, $cookies, req }, inject) {
    
    async function getCount() {
        return await store.dispatch('cart/getCount', { $axios, $cookies });
    }

    async function checkIfItemInCart(itemId) {
        return await store.dispatch('cart/isItemInCart', { itemId, $axios, $cookies });
    }

    async function addItemToCart(itemId) {
        return await store.dispatch('cart/addItem', { itemId, $axios, $cookies });
    }

    async function removeItemFromCart(itemId) {
        return await store.dispatch('cart/removeItem', { itemId, $axios, $cookies });
    }

    inject('cart', { getCount, checkIfItemInCart, addItemToCart, removeItemFromCart });
}