
    export default {
        props: {
            text: String,
            icon: {
                type: [String, Array],
                required: false
            },
            iconEnd: {
                type: Boolean,
                default: false
            },
            iconSize: {
                type: String,
                default: '1x'
            }
        }
    }
