
	import {library} from "@fortawesome/fontawesome-svg-core"
	import {fas} from "@fortawesome/free-solid-svg-icons"
	import {fab} from "@fortawesome/free-brands-svg-icons"
	library.add(fas)
	library.add(fab)

	export default {
		name: 'DefaultLayout',
		data() {
			return {
				darkMode: false,
				plusNetworkId: false,
				loaded: false
			}
		},
		head: {
			script: [
				{ src: 'https://accounts.google.com/gsi/client', async: 'true' },
				{ src: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0', nonce: 'aE3x5StL', crossorigin: 'anonymous', async: 'true' },
				{ src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', async: 'true' }
			],
			meta: [
				{ name: 'keywords', content: 'online guitar lessons, best online guitar lessons, guitar lessons online, best guitar lessons online' },
			]
		},
		methods: {
			async getEducatorNav(){
				const educatorData = await this.$configs.nav(`educator`)
				console.log('educator data', educatorData)
			}
		},
		async created() {
			// this.darkMode = await this.$member.config.darkMode()
			// if(this.darkMode) document.documentElement.setAttribute('data-bs-theme', 'dark');
			this.plusNetworkId = await this.$configs.plusNetwork();
			// console.log('we got plusNetworkId', plusNetworkId);
			// if(plusNetworkId) this.getEducatorNav()
			this.loaded = true
		}
	}
