
export default {
    props: {
        placeholder: {
            type: String,
            default: `Search Lessons, Courses, and More`,
        }
    },
    data() {
        return {
            searchQuery: '',
        }
    },
    methods: {
		doSearch(){
			if(this.searchQuery) location.href = `/search/?q=${this.searchQuery}`
		},
    }
}
