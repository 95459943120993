export const state = () => ({
    itj: {}
  });
  
export const mutations = {
    set(state, result) {
        state.itj = result;
    }
};
  
export const getters = {};


export const actions = {
    async getItj({ commit, state }, { itemId, options, $axios, $cookies }) {
        const postObj = {
            itj: {
                id: itemId[0] == 'j' ? itemId.replace('j', '') : itemId,
                fields: options
            }
        }
        
        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && response.data.itj && Object.keys(response.data.itj)) {
                return response.data.itj;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting jam page:", e);
        }
        return {};
    },
    async getRecommendations({ commit, state }, { options, $axios, $cookies }) {
        console.log("options", options)
        const postObj = {
            itj: {
                set: "recommendations",
                limit: 4,
                fields: options
            }
        }
        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && response.data.itj && Object.keys(response.data.itj)) {
                return response.data.itj;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting recommended ITJ recommendations:", e);
        }
        return {};
    },
}