// plugins/nexus.js
export default function ({ $axios }, inject) {
  const maxRetries = 0; // Maximum number of retries for a failed request
  $axios.defaults.headers.common['Accept'] = 'application/json';

  const logError = (message, response) => console.error(message, response?.data?.message || response);

  const responseHandlers = {
    400: response => logError('Bad Request, check your data:', response),
    401: response => {
      logError('Unauthorized, you might need to log in again:', response);
      // Add re-authentication flow if necessary
    },
    403: response => logError('Forbidden, you do not have permission:', response),
    404: response => logError('Not Found, the requested resource is unavailable:', response),
    500: response => logError('Server Error, try again later:', response),
    default: response => logError(`Unhandled HTTP Error: ${response.status} ${response.statusText}`, response)
  };

  async function call(data, retries = 0) {
    if (!data || Object.keys(data).length === 0) {
      const error = 'No data provided. Nexus calls require a data object.';
      console.error('Nexus API Error:', error);
      throw new Error(error);
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, data);
      return response.data; // Return only the data object from the response
    } catch (error) {
      console.error('Nexus API Error:', error);

      if (error.response) {
        const handler = responseHandlers[error.response.status] || responseHandlers.default;
        handler(error.response);
        if (error.response.status >= 500 && retries < maxRetries) {
          console.log(`Retrying request, attempt ${retries + 1}`);
          return call(data, retries + 1);
        }
        throw new Error(error.response.data.message || `Unhandled HTTP Error: ${error.response.status}`);
      } else if (error.request) {
        console.error('No response received from server.');
        if (retries < maxRetries) {
          console.log(`Retrying request, attempt ${retries + 1}`);
          return call(data, retries + 1);
        }
        throw new Error('No response received from server.');
      } else {
        console.error('Error:', error.message);
        throw new Error(error.message);
      }
    }
  }

  inject('nexus', call);
}
