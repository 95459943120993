
export default {
	data() {
		return {
			logo: `https://df4emreqpcien.cloudfront.net/images/logos/web-header-black.svg`, 
			mobileShops: [
				{
					icon: `apple`,
					label: `App Store`,
					url: `https://itunes.apple.com/us/app/truefire/id690143001?mt=8`,
				},
				{
					icon: `google-play`,
					label: `Play Store`,
					url: `https://play.google.com/store/apps/details?id=com.truefire.android3`,
				}
			]   
		}
	},
	methods: {
		browseLink(){
			let link = '/browse'
	  	if(typeof window !== 'undefined'){
	  		link = 'https://' + location.host + link
	  		link = link.replace('-artists.', '.')
	  		link = link.replace('/artists.', '/')
	  	}
			return link
		}
	}
}
