import { render, staticRenderFns } from "./player.vue?vue&type=template&id=46d356ce"
import script from "./player.vue?vue&type=script&lang=js"
export * from "./player.vue?vue&type=script&lang=js"
import style0 from "./player.vue?vue&type=style&index=0&id=46d356ce&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfHeaderUserbar: require('/usr/src/app/components/tf/header/userbar.vue').default,TfHeaderMidheader: require('/usr/src/app/components/tf/header/midheader.vue').default})
