export const actions = {
    async getItem({ commit, state }, { itemId, options, $axios, $cookies }) {

        const postObj = {
            items: {
                id: itemId,
                fields: options
            }
        }

        try {
            const response = await $axios.post(process.env.nexusUrl, postObj);
            if (response.status == 200 && response.data && (response.data.items || !response.data.items) && Object.keys(response.data.items)) {
                return response.data.items;
            } else {
                throw Error;
            }
        }
        catch (e) {
            console.log("Error getting item:", e);
        }
        return {};
    }
}