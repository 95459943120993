

export default {
    props: {
        dropdown: {
            type: Boolean,
            default: false
        },
        items: Array,
        logoutOption: {
            type: Boolean,
            default: false
        }
    }
}
