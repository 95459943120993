
export default async function ({ $cookies, route, app, store }) {
  const routePath = route.path || '';

  try {

    if($cookies.get('plus_network_id')){
      try{
        store.commit("configs/setPlusNetworkId", $cookies.get('plus_network_id'));
      }catch(e){
        console.error('Error setting plus_network_id from cookie', e);
      }
    }

    // add plus_network_id to cookies to trigger E+ layout
    if (route.query.educator_id && parseInt(route.query.educator_id)) {
      $cookies.set('plus_network_id', route.query.educator_id, {
         path: '/',
         maxAge: 60 * 60 * 24 * 30
       });

      store.commit("configs/setPlusNetworkId", route.query.educator_id);

    }
    const key = routePath.split('/').pop().split('?')[0];

    // store the key in the app context for later use
    app.$layoutKey = key;
  } catch (e) {
    console.error('Error setting cookie', e);
  }
}
