
export default {
    props: {
        navData: Array
    },
    data() {
        return {
            //linkClass: `d-block py-1 px-3 text-nowrap text-dark`,
            nav: []
        }
    },
    methods: {
        showDropdown(i) {
            this.nav[i].subShow = true;
            this.$forceUpdate();
        },
        hideDropdown(i) {
            this.nav[i].subShow = false;
            this.$forceUpdate();
        },
        async searchThisEducator(item) {
            let filterEncoded = ``;
            if (item.search) {
                let filterString = JSON.stringify(item.search);
                filterEncoded = encodeURIComponent(filterString);
            }
            window.location.href = window.location.origin + `/directory?filter=` + filterEncoded;
        }
    },
    created() {
        this.nav = this.navData;
    }
}
