export default function ({ store, $axios, $cookies }, inject) {
    async function get(lessonId) {
        return await store.dispatch('player/getProduct', { lessonId, $axios, $cookies });
    }
    async function getLesson(lessonId) {
        return await store.dispatch('player/getLesson', { lessonId, $axios, $cookies });
    }
    async function getConfig() {
        return await store.dispatch('player/getConfig', { $axios, $cookies });
    }
    inject('player', { get, getLesson, getConfig});
}
