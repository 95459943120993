
	export default {
		props: ['eplus'],
		data() {
			return {
				bottom: [
					{label: `Terms`, url: `/terms`},
					{label: `Privacy`, url: `/privacy-policy`},
					{label: `Help`, url: `/help`},
					{label: `Guides`, url: `/help`},
					{label: `FAQ`, url: `https://truefire.zendesk.com/hc/en-us/categories/200018593`},
					{label: `Rescue`, url: `/rescue`},
				],
			}
		}
	}
