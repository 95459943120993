export default function ({ store, $axios, $cookies }, inject) {

    async function get(id, fields) {
        console.log('get educator fields', id, fields);
        return await store.dispatch('educator/getFields', { id, fields, $axios, $cookies });
    }

    async function subscribe(id, fields) {
        return await store.dispatch('educator/subscribeToEmails', { fields, $axios, });
    }

    inject('educator', { get, subscribe });
}
