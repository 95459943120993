
	export default {
		name: 'SSOAuth',
		props: {
			simple: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				tryCount: 0,
				ssoButtons: [
					{ name: 'Google', buttonClass: 'error', icon: 'google' },
					{ name: 'Apple', buttonClass: 'white black--text', icon: 'apple' },
					{ name: 'Facebook', buttonClass: 'info', icon: 'facebook' },
				],
				errorMessage: false,
				successMessage: false,
				isProcessing: null
			}
		},
		mounted() {
			this.loadFacebook()
		},
		methods: {
			loadFacebook() {
				this.$nextTick(() => {
					if(window.FB && (window.FB.init)){
						window.FB.init({
							appId: '143023449058870',
							xfbml: true,
							version: 'v2.7'
						})
					}
				})
				this.loadGoogle()
			},
			ssoClickFacebook() {
				window.FB.login(response => {
					if (response.authResponse) this.ssoJoin(response.authResponse.accessToken)
					else if (response.status === 'unknown') this.ssoError('Popup closed by user')
				}, { scope: 'email' })
			},

			// google
			googleSuccess(data) {
				const postObject = {
					member: {
						login: {
							sso: {
								provider: 'google',
								token: data.credential
							}
						},
						fields: ['token', 'memberusername']
					}
				};
				this.$nexus(postObject).then(this.ssoSuccess).catch(this.ssoError);
			},
			tryGoogle(){
				console.log('tryGoogle')
				if(window.google){
					window.google.accounts.id.initialize({
						client_id: '969360121366-7709khn7eksjelam3i2n5n1v4o1827ml.apps.googleusercontent.com',
						callback: this.googleSuccess
					})
					window.google.accounts.id.renderButton(
						document.getElementById('googleButtonContainer'),
						{
							type: "standard",
							size: "large",
							theme: "outline",
							text: "sign_in_with",
							shape: "rectangular",
							logo_alignment: "left",
						}
					)
				} else {
					if(this.tryCount < 10){
						console.log('could not find google. trying again')
						setTimeout(this.tryGoogle, 250)
						this.tryCount++
					} else {
						console.log('could not find google. giving up')
					}
				}
			},
			loadGoogle() {
				this.$nextTick(() => {
					this.tryGoogle()
				})
				this.loadApple()
			},
			ssoClickGoogle(){
				if(!this.googleClient){
					this.googleClient = google.accounts.oauth2.initTokenClient({
						client_id: '969360121366-7709khn7eksjelam3i2n5n1v4o1827ml.apps.googleusercontent.com',
					  	scope: 'https://www.googleapis.com/auth/userinfo.email',
					  	callback: this.googleCallback,
					  	error_callback: this.googleErrors
					})
				}
				this.googleClient.requestAccessToken()
			},
			googleCallback(data) {
				if(data.access_token) {
					const postObj = {
						google_token: data.access_token
					}
            		if(this.$root.isLogin) this.ssoLogin(postObj, 'google')
        			else this.ssoJoin(postObj, 'google')
				}
			},
			googleErrors(data){
				console.log('google error',data)
				this.sso.active = false
				this.sso.error = data.message
			},

			appleSuccess(data) {
				const postObject = {
					member: {
						login: {
							sso: {
								provider: 'apple',
								token: data.detail.authorization.id_token
							}
						},
						fields: ['token', 'memberusername']
					}
				};
				this.$nexus(postObject).then(this.ssosuccess).catch(this.ssoError);
			},
			appleError(error) {
				console.log('apple error', error)
			},
			loadApple() {
				this.$nextTick(() => {
					if(window.AppleID){
						console.log("loadApple", window.AppleID)
						window.AppleID.auth.init({
							clientId: 'com.truefire.applesignin',
							scope: 'email',
							redirectURI: location.href,
							state: 'origin:web',
							nonce: Math.floor(Math.random(1024)),
							usePopup: true
						})
					}
				})
				document.addEventListener('AppleIDSignInOnSuccess', this.appleSuccess)
				document.addEventListener('AppleIDSignInOnFailure', this.ssoError)
			},
			ssoClickApple() {
				console.log('ssoClickApple');
				window.AppleID.auth.signIn()
			},

			// global
			closeAlert() {
				this.errorMessage = false
				this.successMessage = false
			},
			ssoClick(ssoItem) {
				this.errorMessage = false
				this.successMessage = false
				this.isProcessing = true
				switch (ssoItem.name.toLowerCase()) {
					case 'facebook': this.ssoClickFacebook(); break;
					case 'apple': this.ssoClickApple(); break;
					case 'google': this.ssoClickGoogle(); break;
				}
			},
			ssoSuccess(response) {
				this.isProcessing = false
				this.successMessage = JSON.stringify(response)
				console.log(`isAuthenticated: ${this.$store.getters['auth/isAuthenticated']}`)
				location.reload();
			},
			ssoError(error) {
				this.isProcessing = false
				let errorMessage
				if (typeof error === 'string')
					errorMessage = error
				else if (error.message)
					errorMessage = error.message
				else if (error.detail && (error.detail.error))
					errorMessage = error.detail.error
				errorMessage = errorMessage.replace(/_/g, ' ')
				errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
				if (errorMessage.toLowerCase().includes('popup'))
					errorMessage = 'You may have closed the popup. Please try again.'
				this.errorMessage = errorMessage
			},

			// post login
			ssoLogin(provider, token) {
				const dataObj = {
					member: {
						login: {
							sso: { provider, token }
						},
						fields: ['token', 'memberusername']
					}
				}
				this.$nexus(dataObj).then(data => {
					console.log('data', data)
				}).catch(error => {
					console.log('error', error)
				})
			},

			// post join
			ssoJoin(provider, token) {
				const dataObj = {
					member: {
						join: {
							sso: { provider, token },
							bypass_trial: true
						},
						fields: ['token', 'memberusername']
					}
				}
				this.$nexus(dataObj).then(data => {
					console.log('data', data)
				}).catch(error => {
					console.log('error', error)
				})
			}

		}
	}
