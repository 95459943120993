
	export default {
		data(){
			return {
				logo: false,
				notificationsMenu: false,
				notificationNum: 0,
				logoWidth: 250,
				currentMobileNav: -1,
				noNotifications: false,
				markingAsRead: false,
				readCount: false,
				notifications: false,
				canGet: true,
				limit: 5,
				start: 0,
				currentMegaNav: -1,
				notificationCount: 0,
				cartCount: 0,
				member: false,
				darkMode: false,
				mobileNavShowing: false,
				notifications: false
			}
		},
		methods: {
			async getNotifications(){
				const notifications = await this.$member.notifications.get()
				if(notifications && (notifications.length)) this.notifications = notifications
				else this.noNotifications = true
			},
			timeAgo(datePosted){
				var diff = new Date().getTime() - datePosted;
				var secondsAgo = Math.floor(diff / 1000 % 60), minutesAgo = Math.floor(diff / 1000 / 60 % 60),
					hoursAgo = Math.floor(diff / (1000 * 60 * 60) % 24), daysAgo = Math.floor(diff / (1000 * 60 * 60 * 24));
				var timeString = '';
				if(daysAgo) timeString = daysAgo == 1 ? 'a day ago' : daysAgo + ' days ago';
				else if(!daysAgo && hoursAgo) timeString = hoursAgo == 1 ? 'an hour ago' : hoursAgo + ' hours ago';
				else if(!daysAgo && !hoursAgo && minutesAgo){
					if(minutesAgo == 1) timeString = 'a minute ago';
					else timeString = minutesAgo < 10 ? 'a few minutes ago' : minutesAgo + ' minutes ago';
				}
				else if(!daysAgo && !hoursAgo && !minutesAgo && secondsAgo) timeString = 'just now';
				return timeString;
			}
		},
		watch: {
			notificationsMenu(){
				if(this.notificationsMenu){
					this.getNotifications()
				}
			}
		},
		async mounted(){
			this.darkMode = await this.$member.config.darkMode()
			this.logo = this.darkMode ? 'https://df4emreqpcien.cloudfront.net/images/logos/logo-white-300@2x.png' : 'https://df4emreqpcien.cloudfront.net/images/logos/web-header-black.svg'
			const member = await this.$member.get(['memberusername'])
			if(member && (member.memberusername)){
				this.member = member
				const notificationCount = await this.$member.notifications.getCount()
				if(notificationCount) this.notificationCount = notificationCount
			}
			this.cartCount = await this.$cart.getCount()
		}
	}
