
  export default {
    props: ['src', 'nolazy', 'alt'],
    data(){
      return {
        imgSrc: false
      }
    },
    computed: {
      generateAlt(){
        return (this.alt) ? this.alt : this.src.split('/').pop().split('.').shift()
      }
    },
    created(){
      try {
        this.imgSrc = this.src
        
        if(this.imgSrc.includes('d2xkd1fof6iiv9')){
          this.imgSrc = this.imgSrc.replace('d2xkd1fof6iiv9', 'df4emreqpcien')
        }
        if(this.imgSrc.includes('cdn.truefire.com')){
          this.imgSrc = this.imgSrc.replace('cdn.truefire.com', 'df4emreqpcien.cloudfront.net')
        }
      } catch(err){  }
    }
  }
