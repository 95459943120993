export const state = () => ({
    cart: {},
    count: 0 // Add count state
  });
  
  export const mutations = {
    SET_COUNT(state, count) {
      state.count = count;
    }
  };
  
  export const actions = {
    async getCount({ commit, state }, { $axios, $cookies }) {        
        const cartCookie = $cookies.get('cart_cookie');
        if(!cartCookie) {
          commit('SET_COUNT', 0);
          return 0;
        }
        const cartItems = String(cartCookie).split('_');
        const count = cartItems.length;
        commit('SET_COUNT', count); // Commit count value to state
        return count;
    },
    async addItem({ commit, state }, { itemId, $axios, $cookies }) {  
      itemId = itemId.toString();
      let cartItems;    
      if (isItemInCartFunction(itemId, $cookies)) {
        return removeItemFunction(itemId, $cookies);
      } 
      const cartCookie = $cookies.get('cart_cookie') || null;
      if (!cartCookie) {
        cartItems = itemId
      }
      else {
        cartItems = cartCookie + '_' + itemId;
      };
      $cookies.set("cart_cookie", cartItems, { path: '/', domain: '.truefire.com', maxAge: 30 * 60 * 60 * 24 });
      
      return cartItems.includes(itemId) ? "successfully added cookie!" : "L";
    },
    async removeItem({ commit, state }, { itemId, $axios, $cookies }) {  
      if (!isItemInCartFunction(itemId, $cookies)) return itemId + " not found in cart";  
      return removeItemFunction(itemId, $cookies);
    },
    async isItemInCart({ commit, state }, { itemId, $axios, $cookies }) {      
      return isItemInCartFunction(itemId, $cookies);
    },
  };

  function isItemInCartFunction(itemId, $cookies){ 
    itemId = itemId.toString();
    const cartCookie = $cookies.get('cart_cookie') || null;
    if (!cartCookie) {
      return false;
    }
    const cartItems = cartCookie.toString().split('_');
    return cartItems.includes(itemId) ? true : false;
  }

  function removeItemFunction(itemId, $cookies) {
    itemId = itemId.toString();

    const cartCookie = $cookies.get('cart_cookie') || null;
    if (!cartCookie) {
      return "no cookies found";
    }

    let cartItems = cartCookie.toString().split('_');
    const index = cartItems.indexOf(itemId);
    cartItems.splice(index, 1);
    if (!cartItems.length) $cookies.remove("cart_cookie", { domain: '.truefire.com' });
    else {
      cartItems = cartItems.join("_");
      $cookies.set("cart_cookie", cartItems, { path: '/', domain: '.truefire.com', maxAge: 30 * 60 * 60 * 24 });
    }

    return itemId + " successfully removed";
  }
  