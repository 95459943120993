
export default {
	props: {
		socialIcons: Array
	},
	data() {
		return {
			socialOverlay: false
		}
	}
}
