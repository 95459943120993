
	import {library} from "@fortawesome/fontawesome-svg-core"
	import {fas} from "@fortawesome/free-solid-svg-icons"
	import {fab} from "@fortawesome/free-brands-svg-icons"
	library.add(fas)
	library.add(fab)

	export default {
		name: 'PlayerLayout',
		data() {
			return {
			}
		},
	}
