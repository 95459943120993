const CONFIG_DUMMY = {
	whitelabel: 'martin'
}

export const state = () => ({
	product: {},
	lesson: {}
})

export const mutations = {
	setProduct(state, data){
		state.product = data
	},
	setLesson(state, data){
		state.lesson = data
	}
};

export const actions = {
	async getProduct({ commit, state }, { lessonId, $axios, $cookies }){
		console.log('lesson id in store is', lessonId)
		try {
			const response = await $axios.get(`${process.env.tfsApiUrl}lesson/c${lessonId}`)
			try {
				commit('setProduct', response.data.product)
				commit('setLesson', response.data.lesson)
			} catch(err){

			}
		} catch(err){}
		return {
			product: state.product,
			lesson: state.lesson
		}
	},
	async getLesson({ commit, state }, { lessonId, $axios, $cookies }){
		try {
			const response = await $axios.get(`${process.env.tfsApiUrl}lesson/v${lessonId}`)
			try {
				commit('setLesson', response.data.lesson)
			} catch(err){
				console.log('error parsing lesson')
			}
		} catch(err){
			console.log('error getting lesson')
		}
		return {
			lesson: state.lesson
		}
	},
	async getConfig({ commit, state }, { $axios, $cookies }){
		return CONFIG_DUMMY
	}
}