
export default {
    props: {
        navData: Array,
        userData: Object,
		educator: Object
    },
    data() {
        return {
            loggedIn: false,
            name: ``,
            cartCount: 0,
			mobileNavShowing: false,
			loginModal: false,
			joinModal: false,
            profileDropdown: {
			    showing: false,
				items: [
					{
						icon: `user`,
						label: `Profile`,
						url: `/dashboard/account-profile/`
					},
					{
						icon: `gear`,
						label: `Account Overview`,
						url: `/dashboard/account-overview/`
					},
					{
						icon: `arrows-rotate`,
						label: `Subscriptions`,
						url: `/dashboard/subscriptions/`
					},
					{
						icon: `tags`,
						label: `Billing & Shipping`,
						url: `/dashboard/account-billing-shipping/`
					},
					{							
                        icon: `calendar`,
						label: `Order History`,
						url: `/dashboard/account-order-history/`
					},
					{
						icon: `envelope`,
						label: `Email Prefs`,
						url: `/dashboard/account-email-preferences/`
					},
				]
			},
            dashboardDropdown: {
                icon: `house`,
                label: `Dashboard`,
                url: `/dashboard`,
                showing: false,
                items: [
                    {
                        icon: `table-cells`,
                        label: `My Courses`,
                        url: `/dashboard/my-courses`
                    },
                    {
                        icon: `guitar`,
						label: `My Recommendations`,
						url: `/dashboard/my-recommendations`
					},
					{
						icon: `music`,
						label: `My Jams`,
						url: `/dashboard/my-jams`
					},
					{
						icon: `compact-disc`,
						label: `My Song Lessons`,
						url: `/dashboard/my-song-lessons`
					},
					{
						icon: `list`,
						label: `My Playlists`,
						url: `/dashboard/my-playlists`
					},
					{
						icon: `map`,
						label: `My Learning Paths`,
						url: `/dashboard/my-learning-paths`
					},
					{
						icon: `play`,
                        label: `My Channels`,
						url: `/dashboard/my-channels`
					},
					{
                        icon: `envelope`,
						label: `My Inbox`,
						url: `/dashboard/my-inbox`
					},
					{
						icon: `comments`,
						label: `My Private Lesson`,
						url: `/dashboard/my-private-lessons`
					},
					{
						icon: `cloud-arrow-down`,
						label: `My Downloads`,
						url: `/dashboard/my-downloads`
                    }
				]
			},
            
        }
    },
    methods: {
    	profileLink(){
    		let url = 'https://' + location.host + `/dashboard/account-profile/`
    		url = url.replace('-artists.', '.')
    		url = url.replace('/artists.', '/')
    		return typeof window !== 'undefined' ? url : `/dashboard/account-profile/`
    	},
    	dashboardLink(){
    		let url = 'https://' + location.host + `/dashboard/`
    		url = url.replace('-artists.', '.')
    		url = url.replace('/artists.', '/')
    		return typeof window !== 'undefined' ? url : `/dashboard/`
    	},
		openModal(type) {
			if (type == 'join') this.joinModal = true;
			else this.loginModal = true;
		}
    },
    async created() {
    	if(typeof window !== 'undefined'){
	    	for(let i = 0; i < this.profileDropdown.items.length; i++){
	    		this.profileDropdown.items[i].url = 'https://' + location.host + this.profileDropdown.items[i].url
	    		this.profileDropdown.items[i].url = this.profileDropdown.items[i].url.replace('-artists.', '.')
	    		this.profileDropdown.items[i].url = this.profileDropdown.items[i].url.replace('/artists.', '/')
	    	}
	    	for(let i = 0; i < this.dashboardDropdown.items.length; i++){
	    		this.dashboardDropdown.items[i].url = 'https://' + location.host + this.dashboardDropdown.items[i].url
	    		this.dashboardDropdown.items[i].url = this.dashboardDropdown.items[i].url.replace('-artists.', '.')
	    		this.dashboardDropdown.items[i].url = this.dashboardDropdown.items[i].url.replace('/artists.', '/')
	    	}

	    }
		this.cartCount = await this.$cart.getCount();
		this.name = await this.$member.get('memberusername');
    }

}
