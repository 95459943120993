
export default {
	props: {
		promo: Object
	},
	data() {
		return {
			expDate: ``,
			exceptions: false
		}
	},
	methods: {
		formatDate() {
			const dateArray = this.promo.enddate.split('-');
			try {
				this.expDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
			} catch(e) {
				console.log('Error formatting expiration date.')
			}
		}
	},
	created() {
		if (this.promo && this.promo.enddate) this.formatDate();
	}
}
