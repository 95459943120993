// middleware/refreshMemberData.js

export default async function ({ store, redirect, $cookies }) {
  console.log("RUNNING MIDDLEWARE");

  const authToken = $cookies.get('auth_token');
  if (!authToken) {
    console.log("No auth token, skipping fetch");
    return;
  }
  store.commit('member/setAuthToken', authToken);

  const skipRefreshOnLoad = store.state.member.skipRefreshOnLoad || [];

  // Log all member fields
  console.log("Current member fields:", store.state.member.member);

  const fieldsToRefresh = Object.keys(store.state.member.member).filter(
    field => !skipRefreshOnLoad.includes(field)
  );

  if (fieldsToRefresh.length === 0) {
    console.log("No eligible fields to update, skipping fetch");
    return;
  }

  await store.dispatch('member/getMemberData', { fields: fieldsToRefresh });
}
