import hash from 'object-hash';

export const state = () => ({
  search: {},
  prefetchTimeouts: {},
});

export const mutations = {
  SET_SEARCH_RESULT(state, { hashKey, result }) {
    // console.log('SET_SEARCH_RESULT', hashKey, result);
    state.search = { ...state.search, [hashKey]: result };
  },
  CLEAR_PREFETCH_TIMEOUT(state, hashKey) {
    if (state.prefetchTimeouts[hashKey]) {
      clearTimeout(state.prefetchTimeouts[hashKey]);
      delete state.prefetchTimeouts[hashKey];
    }
  },
  SET_PREFETCH_TIMEOUT(state, { hashKey, timeout }) {
    state.prefetchTimeouts = { ...state.prefetchTimeouts, [hashKey]: timeout };
  }
};

export const actions = {
  async searchNexus({ commit, state, dispatch }, { searchData, $axios }) {
    // console.log('searchNexus', searchData);
    const hashKey = hash(searchData);
    const currentPage = searchData.course.search.page || 1;

    // Check if the result is already cached
    if (state.search[hashKey]) {
      const cachedResult = JSON.parse(JSON.stringify(state.search[hashKey]));
      // console.log('Cached result', cachedResult);

      // Clear any existing prefetch timeout
      commit('CLEAR_PREFETCH_TIMEOUT', hashKey);

      // Dispatch prefetch action if there are more pages
      if (cachedResult.course && cachedResult.course.summary.pages > 1) {
        const prefetchTimeout = setTimeout(() => {
          dispatch('prefetchNextPage', { searchData, currentPage, totalPages: cachedResult.course.summary.pages, $axios });
          commit('CLEAR_PREFETCH_TIMEOUT', hashKey);
        }, 3000); // 3 seconds
        commit('SET_PREFETCH_TIMEOUT', { hashKey, timeout: prefetchTimeout });
      }
      
      return cachedResult;
    }

    try {
      const response = await $axios.post(process.env.nexusUrl, searchData);
      const result = response.data;

      // Save the result to the store
      commit('SET_SEARCH_RESULT', { hashKey, result });

      // Dispatch prefetch action if there are more pages
      if (result.course && result.course.summary.pages > 1) {
        const prefetchTimeout = setTimeout(() => {
          dispatch('prefetchNextPage', { searchData, currentPage, totalPages: result.course.summary.pages, $axios });
          commit('CLEAR_PREFETCH_TIMEOUT', hashKey);
        }, 3000); // 3 seconds
        commit('SET_PREFETCH_TIMEOUT', { hashKey, timeout: prefetchTimeout });
      }

      return result;
    } catch (error) {
      console.error('Error searching', error);
      return null;
    }
  },

  async prefetchNextPage({ commit, state }, { searchData, currentPage, totalPages, $axios }) {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      const nextPageData = { ...searchData, course: { ...searchData.course, search: { ...searchData.course.search, page: nextPage } } };
      const nextPageHashKey = hash(nextPageData);

      if (!state.search[nextPageHashKey]) {
        try {
          const nextResponse = await $axios.post(process.env.nexusUrl, nextPageData);
          const nextResult = nextResponse.data;
          commit('SET_SEARCH_RESULT', { hashKey: nextPageHashKey, result: nextResult });
        } catch (error) {
          console.error('Error prefetching next page', error);
        }
      }
    }
  },

  async setEducatorSet({}, { set, $cookies }) {
    try {
      $cookies.set('plus_network_set', set, {
        path: '/',
        domain: '.truefire.com'
      });
      return true
    } catch(e) {
      console.log(e);
      return false;
    }
  },

  async getEducatorSet({}, {$cookies}) {
    return $cookies.get('plus_network_set');
  },

};